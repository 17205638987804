import React, { useMemo, Suspense } from "react";
import { Canvas, useLoader } from "react-three-fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "drei";
import "./styles.css";
import duckUrl from "./assets/bloki.gltf";
import logo from './assets/bitomer_logo.png';

function Blocks({ position, ...props }) {
  const obj = useLoader(GLTFLoader, duckUrl);

  const scene = useMemo(() => {
    return obj?.scene?.clone(true);
  }, [obj]);

  return (
    <Suspense fallback={null}>
      <primitive object={scene} position={position} {...props} />
    </Suspense>
  );
}

function Scene() {
  return (
    <>

      <Blocks position={[0, -2, 0]} rotation={[0,-0.7,0]}/>

    </>
  );
}

export default function App() {

  const [showContact, setShowContact] = React.useState(false);

  const handleContact = ()  =>{
    setShowContact(true);
  };

  return (
      <>
      <Canvas
    shadowMap
    gl={{ alpha: false, antialias: false }}

    onCreated={(state) => state.gl.setClearColor('#DFE0D5')}>

      <ambientLight />

      <OrbitControls
  autoRotate
  enablePan={false}
  enableZoom={false}
  enableDamping
  dampingFactor={0.05}
  autoRotateSpeed={-0.3}
  maxPolarAngle={1.7}
  minPolarAngle={Math.PI/10}
  />

      <Suspense fallback={null}>
        <Scene />
      </Suspense>
    </Canvas>
      <img src={logo} style={{position: 'absolute', top: '2%' , left :'2%' , width: '170px'}}/>
      <div style={{position:'absolute', bottom:'2%', right: '2%', fontFamily: 'Tahoma, sans-serif', textAlign:"right"}}>
  Bitomer Ltd,<br/>Software development
{!showContact && <div onClick={handleContact}>Contact</div>}
  {showContact && <div><a href="mailto:andrzej@bitomer.com">Email Us</a></div>}
  </div>
  </>
  );
}
